import React from "react"
import Header404 from "../components/Header/Header404"
import Footer from "../components/Footer/Footer"

import "../assets/scss/www.scss"

const HomeLayout = ({data, children}) => (
  <>
    <Header404 image={data} />
      {children}
    <Footer image={data} />
  </>
)

export default HomeLayout
