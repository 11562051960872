import React from "react"
import Layout404 from "../layouts/Layout404"
import { graphql } from "gatsby"
import SEO from "../components/Seo"

const NoFoundPage = ({data}) => (
  <>
    <SEO title="Organizacja pogrzebu, pochówku, kremacji" />
    <Layout404 data={data}>
        <div className="container">
            <div className="page-content content">
                <section className="container">
                    <h1>404</h1>
                    <h2>Strona o podanym adresie nie istnieje.</h2>
                    <p>Szanowni Państwo z przykrością informujemy ze dana podstrona o adresie XXX nie istnieje.</p>
                </section>
            </div>
        </div>
    </Layout404>
  </>
)
export const pageQuery = graphql`
    query {
        logo: file(relativePath: { eq: "logo_poziome.png" }) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export default NoFoundPage
