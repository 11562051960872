import React from "react"
import Img from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../utils/icons'
import Navigation from "../Navigation/Navigation"


const Header = ({ image }) => (
    <>
    <div className="header">
        <div className="container">
            <div className="header__top level is-mobile">
                <a className="header__top__brand level-item" href="/">
                    <Img className="header__top__logo" fluid={image.logo.childImageSharp.fluid} alt="ABRAM"/>
                </a>
                <div className="header__top__info level-item level-right">
                    <div className="level">
                        <div className="level-left info">
                            <div className="info__icon">
                                <FontAwesomeIcon icon={'clock'} />
                            </div>
                            <div className="info__data">
                                24 h / 7 dni
                            </div>
                        </div>
                        <div className="level-left info">
                            <div className="info__icon">
                                <FontAwesomeIcon icon={'phone-alt'} />
                            </div>
                            <div className="info__data">
                                62 737-10-40
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Navigation />
        </div>
    </div>
    </>
)

export default Header
